import {
	useNavigate,
	useNavigation,
} from 'react-router-dom';

import classes from './ScratchGameView.module.css';

function ScratchGameView({ method, game }) {
	const navigate = useNavigate();
	const navigation = useNavigation();

	const isSubmitting = navigation.state === 'submitting';

	function cancelHandler() {
		navigate('..');
	}

	return (
		<div className="w-100 text-center">
			<div className='w-100 h1'>{game.name} ({game.id})</div>
			<div className={`col-12 d-flex justify-content-center`}>
				<div className={`p-1 w-100 justify-content-end text-end ${classes["left-label-spacing"]}`}>Price</div><div className="p-1 w-100 text-start">{game.price}</div>
			</div>
			<div className={`col-12 d-flex justify-content-center`}>
				<div className={`p-1 w-100 justify-content-end text-end ${classes["left-label-spacing"]}`}>Top Prize</div><div className="p-1 w-100 text-start">{game.top_prize}</div>
			</div>
			<div className={`col-12 d-flex justify-content-center `}>
				<div className={`p-1 w-100 justify-content-end text-end ${classes["left-label-spacing"]}`}>Overall Odds</div><div className="p-1 w-100 text-start">{game.overall_odds}</div>
			</div>
			<div className={`col-12 d-flex justify-content-center `}>
				<div className={`p-1 w-100 justify-content-end text-end ${classes["left-label-spacing"]}`}>Start Date</div><div className="p-1 w-100 text-start">{game.start_date}</div>
			</div>
			<div className={`col-12 d-flex justify-content-center `}>
				<div className={`p-1 w-100 justify-content-end text-end ${classes["left-label-spacing"]}`}>End Date</div><div className="p-1 w-100 text-start">{game.end_date}</div>
			</div>
			<br />
			<table className="table table-light table-striped">
				<thead className="table-dark">
					<tr className="text-center">
						<th scope="col" colSpan="3" className="table-light d-none d-lg-table-cell"></th>
						<th scope="col" colSpan="1" className="table-light d-lg-none"></th>
						<th scope="col" colSpan="2">Odds</th>
					</tr>
					<tr className="text-end">
						<th scope="col">Amount</th>
						<th scope="col" className="d-none d-lg-table-cell">Odds</th>
						<th scope="col" className="d-none d-lg-table-cell">Prize Count</th>
						<th scope="col">Prize or Greater</th>
						<th scope="col">Extrapolated</th>
					</tr>
				</thead>
				<tbody>
					{game.odds.map((odds) => (
						<tr key={`${odds.amount}`}>
							<td scope="row" className="text-end">{odds.amount}</td>
							<td className="text-end d-none d-lg-table-cell">{odds.odds}</td>
							<td className="text-end d-none d-lg-table-cell">{odds.prize_count.toLocaleString()}</td>
							<td className="text-end">{odds.prizeOrGreaterOdds.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</td>
							<td className="text-end">{(odds.prizeOrGreaterOddsExtrapolated ? odds.prizeOrGreaterOddsExtrapolated.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}) : 'NA')}</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="col-12 d-flex justify-content-center">
				<button type="button" className="btn btn-secondary" onClick={cancelHandler} disabled={isSubmitting}>
					Back
				</button>
			</div>
		</div>
	);
}

export default ScratchGameView;
