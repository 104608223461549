// import { useLoaderData } from 'react-router-dom';
import { Link } from 'react-router-dom';

import classes from './ScratchGamesList.module.css';

const sortGames = (games) => {
	games.sort((a, b) => {
		if (a.price === b.price) {
			if (a.name < b.name) {
				return -1;
			}
			return 1;
		}

		if (a.price < b.price) {
			return -1;
		}

		return 1;
	});

	return games;
};

const groupByDollarAmount = (games) => {
	const groups = [];

	let price = games[0].price;
	groups[0] = new Array(games[0]);
	for (let i = 1; i < games.length; i++) {
		const game = games[i];
		if (price === game.price) {
			groups[groups.length - 1].push(game);
		} else {
			price = game.price;
			groups.push(new Array(game));
		}
	}

	return groups;
};

function ScratchGamesList({ games }) {
	// const events = useLoaderData();

	games = sortGames(games);
	const gameDollarGroups = groupByDollarAmount(games);

	return (
		<div className={classes.events}>
			<h1 className="text-center">Minnesota Scratch Tickets</h1>
			<div className="accordion" id="dollarGroupsAccordion">
				{gameDollarGroups.map((group) => (
					<div className="accordion-item" key={`group-item-${group[0].price}`}>
						<h2 className="accordion-header" key={`group-item-header-${group[0].price}`}>
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#group-${group[0].price}`} aria-expanded="false" aria-controls={`group-${group[0].price}`}>
								$ {group[0].price}
							</button>
						</h2>
						<div id={`group-${group[0].price}`} key={`group-${group[0].price}`} className="accordion-collapse collapse" data-bs-parent="#dollarGroupsAccordion">
							<div className="accordion-body">
								{group.map((game) => (
									<div className="mb-3" key={`game-${game.id}`}>
										<Link to={`/scratch-game/${game.PK}-${game.id}`} >
											<div>
												<div className="h2 text-center">{game.name}</div>
											</div>
										</Link>
										<div className="row">
											<div className="col-6 col-md-3 text-end">TOP PRIZE: </div>
											<div className="col-6 col-md-3 text-start">{game.top_prize}</div>
											<div className="col-6 col-md-3 text-end">Overall odds: </div>
											<div className="col-6 col-md-3 text-start">{game.overall_odds}</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

//      <ul className="row justify-content-md-center">
//        {games.map((game) => (
//          <li key={game.id} className={classes.item}>
//            <Link to={`/scratch-game/${game.PK}-${game.id}`}>
//              <div className={classes.content}>
//                <span className="h2">{game.name}&nbsp;</span>
//                <span>{game.top_prize}</span>&nbsp;
//                <span>{game.overall_odds}</span>&nbsp;
//                <span>{game.price}</span>
//              </div>
//            </Link>
//          </li>
//        ))}
//      </ul>


export default ScratchGamesList;
