import { useRouteLoaderData, json, defer } from 'react-router-dom';

import ScratchGameView from '../components/ScratchGameView'

function ViewScratchGamePage() {
//  const data = useRouteLoaderData('event-detail');
	const data = useRouteLoaderData('scratch-game-detail');

  return <ScratchGameView method="patch" game={data.event} />;
}

export default ViewScratchGamePage;

async function loadEvent(id) {
	const stateAndId = id.split('-');
  const response = await fetch('https://v76prxs7i6.execute-api.us-east-1.amazonaws.com/dev/scratch-games/' + stateAndId[0] + "/" + stateAndId[1]);

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch details for selected event.' },
      {
        status: 500,
      }
    );
  } else {
    const resData = await response.json();
    const myObj = JSON.parse(resData.body);
    if (myObj.Item.odds && myObj.Item.odds.length > 0) {
		myObj.Item.odds.sort((a,b) => (a.amount > b.amount) ? 1 : ((b.amount > a.amount) ? -1 : 0));
	}
    return myObj.Item;
  }
}

export async function loader({ request, params }) {
  const id = params.eventId;

  return defer({
    event: await loadEvent(id),
//    events: loadEvents(),
  });
}

