import { createSlice } from '@reduxjs/toolkit'

const initialAuthState = {
	isAuthenticated: false,
	userName: null,
	firstName: null,
	lastName: null,
	email: null,
	token: null,
	refresh_token: null,
};

const authSlice = createSlice({
	name: 'authentication',
	initialState: initialAuthState,
	reducers: {
		login(state, action) {
			console.log(action.payload);
			state.isAuthenticated = true;
			state.userName = action.payload.userName;
			state.token = action.payload.token;
			state.refresh_token = action.payload.refresh_token;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
		},
		logout(state) {
			state.isAuthenticated = false;
			state.userName = initialAuthState.userName;
			state.token = initialAuthState.token;
			state.refresh_token = initialAuthState.refresh_token;
			state.firstName = initialAuthState.firstName;
			state.lastName = initialAuthState.lastName;
			state.email = initialAuthState.email;
		}
	},
});

export const authActions = authSlice.actions;

export default authSlice.reducer;