import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, json, defer, Await } from 'react-router-dom';
import PageContent from '../components/PageContent';
import { pricesAndPrizesActions } from '../store/scratchgames'

const calculateMinTixPriceFromBudget = (pricesAndPrizes, budget, min) => {
	if (min && min <= budget) {
		return min;
	}

	for (let i = 0; i < pricesAndPrizes.length; i++) {
		if (pricesAndPrizes[i].price <= budget) {
			return pricesAndPrizes[i].price;
		}
	}

	return pricesAndPrizes[0].price;
}
const calculateMaxTixPriceFromBudget = (pricesAndPrizes, budget, max) => {
	if (max && max <= budget) {
		return max;
	}

	for (let i = pricesAndPrizes.length - 1; i >= 0; i--) {
		if (pricesAndPrizes[i].price <= budget) {
			return pricesAndPrizes[i].price;
		}
	}

	return pricesAndPrizes[pricesAndPrizes.length - 1].price;
}

const calculateAvailableMaximumPrices = (pricesAndPrizes, budget) => {
	let retVal = new Array();

	for (let i = 0; i < pricesAndPrizes.length; i++) {
		if (pricesAndPrizes[i].price <= budget) {
			retVal.push(pricesAndPrizes[i].price);
		}
	}

	return retVal;
}

const calculateAvailableMinimumPrices = (pricesAndPrizes, budget) => {
	let retVal = new Array();

	for (let i = 0; i < pricesAndPrizes.length; i++) {
		if (pricesAndPrizes[i].price <= budget) {
			retVal.push(pricesAndPrizes[i].price);
		}
	}

	return retVal;
}

const calculateAvailablePrizes = (pricesAndPrizes, minTixPrice, maxTixPrice) => {
	let retVal = new Array();

	for (let i = 0; i < pricesAndPrizes.length; i++) {
		if (pricesAndPrizes[i].price <= maxTixPrice && pricesAndPrizes[i].price >= minTixPrice) {
			for (let x = 0; x < pricesAndPrizes[i].prizes.length; x++) {
				if (!retVal.includes(pricesAndPrizes[i].prizes[x])) {
					retVal.push(pricesAndPrizes[i].prizes[x]);
				}
			}
		}
	}

	retVal.sort((a, b) => {
		if (a === b) {
			return 0;
		}

		if (a < b) {
			return -1;
		}

		return 1;
	});

	return retVal;
}

const validateForSubmit = (budget, minTixPrice, maxTixPrice, prizeSelections) => {
	let retVal = false;

	if (budget && minTixPrice && maxTixPrice && prizeSelections && prizeSelections.length > 0) {
		retVal = true;
		if (minTixPrice > maxTixPrice) {
			retVal = false;
		}
	}

	return retVal;
}

const getBudgetInfo = async (budget, minTixPrice, maxTixPrice, myPrizeSelections) => {

	const response = await fetch('https://v76prxs7i6.execute-api.us-east-1.amazonaws.com/dev/scratch-games/budget-calc',
		{
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify({
				"budget": budget,
				"minTixPrice": minTixPrice,
				"maxTixPrice": maxTixPrice,
				"prizes": myPrizeSelections
			})
		});

	if (!response.ok) {
		// return { isError: true, message: 'Could not fetch events.' };
		// throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
		//   status: 500,
		// });
		throw json(
			{ message: 'Could not fetch events.' },
			{
				status: 500,
			}
		);
	} else {
		const resData = await response.json();
		//	    const myObj = JSON.parse(resData.body);
		for (let i = 0; i < resData.length; i++) {
			resData[i].odds.sort((a,b) => (a.extrapolated - b.extrapolated));
		}
		return resData;
	}
}

function HomePage() {
	const dispatch = useDispatch();
	const isAuth = useSelector(state => state.auth.isAuthenticated);
	const firstName = useSelector(state => state.auth.firstName);
	const access_token = useSelector(state => state.auth.token);
	const budget = useSelector(state => state.pricesAndPrizes.budget);
	const results = useSelector(state => state.pricesAndPrizes.homeBudgetResults);
	let minTixPrice = useSelector(state => state.pricesAndPrizes.minTixPrice);
	let maxTixPrice = useSelector(state => state.pricesAndPrizes.maxTixPrice);
	const prizeSelections = useSelector(state => state.pricesAndPrizes.prizeSelection);
	const pricesAndPrizes = useSelector(state => state.pricesAndPrizes.pricesAndPrizes);
	minTixPrice = calculateMinTixPriceFromBudget(pricesAndPrizes, budget, minTixPrice);
	maxTixPrice = calculateMaxTixPriceFromBudget(pricesAndPrizes, budget, maxTixPrice);
	const availMinPrices = calculateAvailableMinimumPrices(pricesAndPrizes, budget);
	const availMaxPrices = calculateAvailableMaximumPrices(pricesAndPrizes, budget);
	const availPrizes = calculateAvailablePrizes(pricesAndPrizes, minTixPrice, maxTixPrice);

	let myPrizeSelections = prizeSelections.slice();
	let removeSelections = [];
	for (let i = 0; i < prizeSelections.length; i++) {
		const num = prizeSelections[i];
		const numInt = parseInt(num);
		if (availPrizes.indexOf(numInt) < 0) {
			console.log("remove " + numInt);
			console.log("indexOf num: " + myPrizeSelections.indexOf(num));
			removeSelections.push(num);
			myPrizeSelections.splice(myPrizeSelections.indexOf(num), 1);
		}
	}
	console.log(myPrizeSelections);
	console.log(removeSelections);
	//	if (removeSelections.length > 0) {
	//		for (let i = 0; i < removeSelections.length; i++) {
	//			const idx = prizeSelections.indexOf(removeSelections[i]);
	//			if (idx >= 0) {
	//				prizeSelections.splice(idx, 1);
	//			}
	//		}
	//	}

	//	const prices = calculatePrices(pricesAndPrizes, budget, minTixPrice, maxTixPrice)
	useEffect(() => {
	}, [firstName, access_token, budget, minTixPrice, maxTixPrice, prizeSelections, results]);

	const budgetChangeHandler = (event) => {
		dispatch(pricesAndPrizesActions.changeBudget(parseInt(event.target.value)));
	};

	const minTixChangeHandler = (event) => {
		dispatch(pricesAndPrizesActions.changeMinTixPrice(parseInt(event.target.value)));
	};

	const maxTixChangeHandler = (event) => {
		dispatch(pricesAndPrizesActions.changeMaxTixPrice(parseInt(event.target.value)));
	};

	const prizeSelectionHandler = (event) => {
		var options = event.target.options;
		var value = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}
		dispatch(pricesAndPrizesActions.changePrizeSelection(value));
	};

	const removePrizeSelectionHandler = (event) => {
		console.log(prizeSelections);
		const newList = prizeSelections.filter((itm) => {
			console.log(itm + " vs " + event.target.value);
			return itm !== event.target.value;
		});
		console.log(newList);
		dispatch(pricesAndPrizesActions.changePrizeSelection(newList));
	}

	const isSubmittable = validateForSubmit(budget, minTixPrice, maxTixPrice, prizeSelections);

	const submitHandler = async (event) => {
		event.preventDefault();
		const results = await getBudgetInfo(budget, minTixPrice, maxTixPrice, myPrizeSelections);
		console.log(results);
		dispatch(pricesAndPrizesActions.changeHomeBudgetResults(results));
	}

	return (
		<PageContent title={`${isAuth ? 'Welcome! ' + firstName : ''}`}>
			<form onSubmit={submitHandler}>
				<h1>Budget</h1>
					<div className="row p-3">
						<div className="col-6 col-md-2 text-end pb-2"><label htmlFor="budget">Budget</label></div>
						<div className="col-6 col-md-2 text-start pb-2"><input id="budget" type="number" name="budget" placeholder="Budget $" style={{width: "5em"}} required value={budget} onChange={budgetChangeHandler} /></div>
						<div className="col-6 col-md-2 text-end pb-2"><label htmlFor="minTixPrice" >Min Ticket Price</label></div>
						<div className="col-6 col-md-2 text-end pb-2">
							<select className="form-select form-select-sm" aria-label=".form-select-sm minTixPrice" data-width="fit" required value={minTixPrice} onChange={minTixChangeHandler}>
								{availMinPrices.map((price) => (
									<option key={`min-${price}`} value={price}>${price}</option>
								))}
							</select>
						</div>
						<div className="col-6 col-md-2 text-end pb-2"><label htmlFor="maxTixPrice">Max Ticket Price</label></div>
						<div className="col-6 col-md-2 text-start pb-2">
							<select className="form-select form-select-sm" aria-label=".form-select-sm maxTixPrice" data-width="auto" required value={maxTixPrice} onChange={maxTixChangeHandler}>
								{availMaxPrices.map((price) => (
									<option key={`max-${price}`} value={price}>${price}</option>
								))}
							</select>
						</div>
						<div className='col-12 pb-1 justify-content-start text-start bg-light'>
							<span className="label pe-2" >Prize Selection:</span>
							{myPrizeSelections.map((prize) => (
								<span className="badge rounded-pill bg-info text-dark">${prize}<button type="button" style={{ width: ".65em", height: ".65em" }} btn-close-width=".5em" className="btn-close" aria-label="Close" value={prize} onClick={removePrizeSelectionHandler}></button></span>
							))}
						</div>
						<div className="col-12 text-start pb-2 justify-content-center">
							<select multiple className="form-select" aria-label=".form-select-sm prizeSelection" data-width="10" onChange={prizeSelectionHandler} value={myPrizeSelections}>
								{availPrizes.map((price) => (
									<option key={`prize-${price}`} value={price}>${price}</option>
								))}
							</select>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-3"><button className="btn btn-primary" disabled={!isSubmittable}>Submit</button></div>
					</div>
			</form>
			<div className="pt-5">
				<div className="accordion" id="accordionPanelsStayOpenExample">
					{results.map((result) => (
						<div className="accordion-item" key={`result-${result.prize}`}>
							<h2 className="accordion-header" id={`h2-result-${result.prize}`}>
								<button className={`${result === results[0] ? 'accordion-button' : 'accordion-button collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#body-result-${result.prize}`} aria-expanded={`${result === results[0] ? 'true' : 'false'}`} aria-controls={`body-result-${result.prize}`}>
									${result.prize}
								</button>
							</h2>
							<div id={`body-result-${result.prize}`} className={`container-fluid accordion-collapse collapse ${result === results[0] ? 'show' : ''}`} aria-labelledby={`h2-result-${result.prize}`}>
								<div className="accordion-body col-12">
									<div className="row col-12">
										<div className="col-4 text-end h3">Name</div>
										<div className="col-4 h3">Odds</div>
										<div className="col-4 h3">Extrapolated</div>
									</div>
									{result.odds.map((odds) => (
										<div className="row col-12">
											<div className="col-4 text-end">{odds.name}</div>
											<div className="col-4">{odds.odds.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
											<div className="col-4">{odds.extrapolated.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
										</div>
									))}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</PageContent>
	);
}

export default HomePage;

export function loader() {
	//	const pricesAndPrizes = useSelector(state => state.pricesAndPrizes);
	//	return pricesAndPrizes;
	//	return defer({
	//		pricesAndPrizes: loadPricesAndPrizes2(),
	//	});
}
