import { CognitoJwtVerifier } from "aws-jwt-verify";
import { json, redirect } from "react-router-dom";
import { authActions } from "../store/auth";

const verifier = CognitoJwtVerifier.create({
	userPoolId: "us-east-1_aoH8uhChP",
	tokenUse: "id",
	clientId: "24tsm435un1qlckgbpgi1tg9kc",
});

export function getTokenDuration() {
	const storedExpirationDate = localStorage.getItem('expiration');
	const expirationDate = new Date(storedExpirationDate);
	const now = new Date();
	const duration = expirationDate.getTime() - now.getTime();
	return duration;
}

export function getAuthToken() {
	const token = localStorage.getItem('token');

	if (!token) {
		return null;
	}

	const tokenDuration = getTokenDuration();

	if (tokenDuration < 0) {
		return 'EXPIRED';
	}

	return token;
}

export function tokenLoader() {
	return getAuthToken();
}

export function checkAuthLoader() {
	const token = getAuthToken();

	if (!token) {
		const cognitoLoginUrl = process.env.REACT_APP_COGNITO_ENDPOINT+"/login?scope=openid&response_type=code&client_id="+process.env.REACT_APP_COGNITO_CLIENT_ID+"&redirect_uri="+process.env.REACT_APP_COGNITO_LOGIN_RESPONSE_URL;
		return redirect(cognitoLoginUrl);
	}

	return null;
}

export async function getTokensFromCode(code, dispatch) {

	console.log("here");
	const val = localStorage.getItem(code);
	if (!val) {
		if (val === code) {
			console.log("same code - returning...");
			return;
		}
		localStorage.setItem("code", code);
	} else {
		console.log("returning...");
		return;
	}

	const url = process.env.REACT_APP_COGNITO_ENDPOINT + "/oauth2/token?client_id=" + process.env.REACT_APP_COGNITO_CLIENT_ID + "&grant_type=authorization_code&redirect_uri=" + process.env.REACT_APP_COGNITO_LOGIN_RESPONSE_URL + "&code=" + code;
	console.log("fetching - " + url);
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});

	console.log(response);

	if (response.status === 422 || response.status === 401) {
		return response;
	}

	if (!response.ok) {
		throw json({ message: 'Could not authenticate user.' }, { status: 500 });
	}

	const resData = await response.json();
	console.log(resData);

	try {
		const payload = await verifier.verify(
			resData.id_token
		);
		console.log("Token is valid. Payload:", payload);
		localStorage.setItem("token", resData.access_token);
		localStorage.setItem("username", payload["cognito:username"]);
		dispatch(authActions.login({
			isAuthenticated: true,
			userName: payload["cognito:username"],
			token: resData.access_token,
			refresh_token: resData.refresh_token,
			firstName: payload["given_name"],
			lastName: payload["family_name"],
			email: payload["email"]
		}));
	} catch (err) {
		console.log("Token not valid! - "+err.message);
	}
}
