import { Suspense } from 'react';
import { useLoaderData, json, defer, Await } from 'react-router-dom';

import ScratchGamesList from '../components/ScratchGamesList';

function ScratchGamesPage() {
  const { scratchGames } = useLoaderData();

  return (
    <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
      <Await resolve={scratchGames}>
        {(loadedGames) => <ScratchGamesList games={loadedGames} />}
      </Await>
    </Suspense>
  );
}

export default ScratchGamesPage;

async function loadScratchGames() {
  const response = await fetch('https://v76prxs7i6.execute-api.us-east-1.amazonaws.com/dev/scratch-games?state=MN',
  	{method: 'GET', mode: 'cors'});

  if (!response.ok) {
    // return { isError: true, message: 'Could not fetch events.' };
    // throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
    //   status: 500,
    // });
    throw json(
      { message: 'Could not fetch events.' },
      {
        status: 500,
      }
    );
  } else {
    const resData = await response.json();
    const myObj = JSON.parse(resData.body);
    return myObj.Items;
  }
}

export function loader() {
  return defer({
    scratchGames: loadScratchGames(),
  });
}
