import { Outlet } from 'react-router-dom';

import ScratchGameNavigation from '../components/ScratchGameNavigation';

function ScratchGameRootLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default ScratchGameRootLayout;
