import { Form, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classes from './MainNavigation.module.css';

function MainNavigation() {
	const access_token = useSelector(state => state.auth.token);
	const username = useSelector(state => state.auth.firstName);

	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
			<NavLink
				to="/"
				className="navbar-brand"
				end
			>
				Home
			</NavLink>
			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarNavDropdown">
				<ul className="navbar-nav mr-auto">
					<li className="nav-item active">
						<NavLink
							to="/scratch-game"
							className="nav-link"
						>
							Games
						</NavLink>
					</li>
				</ul>
				<ul className="navbar-nav mr-auto">
					<li className="nav-item active">
						<NavLink
							to="/budgets"
							className="nav-link"
						>
							My Budgets
						</NavLink>
					</li>
				</ul>
				<div className="col col-lg-9"></div>
				{!access_token && (
					<a href={`${process.env.REACT_APP_COGNITO_ENDPOINT}/login?scope=openid&response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_RESPONSE_URL}`} method="get">
						<button type="button" className="btn btn-dark">Login</button>
					</a>
				)}
				{access_token && (
					<div className="dropdown">
						<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							{username}
						</button>

						<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<a className="dropdown-item" href={`${process.env.REACT_APP_COGNITO_ENDPOINT}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGIN_RESPONSE_URL}`}>Logout</a>
						</div>
					</div>
				)}
			</div>
		</nav>
	);
}

export default MainNavigation;
