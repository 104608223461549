import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import ViewScratchGamePage, { loader as viewScratchGameLoader } from './pages/ViewScratchGame';
import ErrorPage from './pages/Error';
import ScratchGamesPage, { loader as scratchGamesLoader } from './pages/ScratchGames';
import ScratchGameRootLayout from './pages/ScratchGameRoot';
import HomePage, { loader as loadPricesAndPrizes } from './pages/Home';
import RootLayout from './pages/Root';
import NewsletterPage, { action as newsletterAction } from './pages/Newsletter';
import AuthenticationPage, { action as authAction } from './pages/Authentication';
import { action as logoutAction } from './pages/Logout';
import { checkAuthLoader, tokenLoader } from './util/auth';

const router = createBrowserRouter([
	{
		path: '/',
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		id: 'root',
//		loader: tokenLoader,
		children: [
			{ index: true, element: <HomePage /> },
			{
				path: 'scratch-game',
				element: <ScratchGameRootLayout />,
				children: [
					{
						index: true,
						element: <ScratchGamesPage />,
						loader: scratchGamesLoader,
					},
					{
						path: ':eventId',
						id: "scratch-game-detail",
						element: <ViewScratchGamePage />,
						loader: viewScratchGameLoader,
					},
				],
			},
			{
				path: 'auth',
				element: <AuthenticationPage />,
				action: authAction,
			},
			{
				path: 'budgets',
				element: <NewsletterPage />,
				action: newsletterAction,
				loader: checkAuthLoader,
			},
			{
				path: '/logout',
				action: logoutAction,
			},
		],
	},
]);

function App() {
//	require('dotenv').config();
	return <RouterProvider router={router} />;
}

export default App;
