import { createSlice } from '@reduxjs/toolkit'

import { json } from 'react-router-dom';

async function loadPricesAndPrizes() {
	const response = await fetch('https://v76prxs7i6.execute-api.us-east-1.amazonaws.com/dev/scratch-games/prices-prizes?state=MN',
		{ method: 'GET', mode: 'cors' });

	if (!response.ok) {
		// return { isError: true, message: 'Could not fetch events.' };
		// throw new Response(JSON.stringify({ message: 'Could not fetch events.' }), {
		//   status: 500,
		// });
		throw json(
			{ message: 'Could not fetch events.' },
			{
				status: 500,
			}
		);
	} else {
		const resData = await response.json();
		const myObj = JSON.parse(resData.body);
		return myObj.Items;
	}
}

async function loadPricesAndPrizes2() {
	let retVal = new Array();

	retVal.push(pricesAndPrizesInit(1, [1, 2, 5, 10, 15, 20, 30, 50, 100, 500, 1000, 2000, 5000]));
	retVal.push(pricesAndPrizesInit(2, [2, 5, 10, 15, 20, 25, 30, 50, 100, 500, 1000, 10000]));
	retVal.push(pricesAndPrizesInit(3, [3, 5, 6, 8, 9, 10, 15, 20, 25, 30, 45, 50, 75, 100, 150, 200, 250, 300, 500, 1000, 1500, 2000, 2500, 5000, 7000, 20000, 21000, 25000, 30000]));
	retVal.push(pricesAndPrizesInit(5, [5, 10, 15, 20, 25, 30, 40, 50, 75, 100, 150, 200, 500, 1000, 5000, 10000, 75000, 100000]));
	retVal.push(pricesAndPrizesInit(10, [10, 20, 25, 30, 40, 50, 75, 100, 200, 500, 1000, 5000, 10000, 20000, 150000, 200000]));
	retVal.push(pricesAndPrizesInit(20, [20, 25, 30, 45, 50, 60, 75, 100, 125, 150, 200, 250, 400, 500, 1000, 2500, 5000, 25000, 50000, 300000, 500000]));
	retVal.push(pricesAndPrizesInit(50, [50, 75, 100, 250, 500, 1000, 2000, 2500, 5000, 10000, 20000, 25000, 50000, 100000]));

	return {
		"budget": 50,
		"minTixPrice": 1,
		"maxTixPrice": 50,
		"prizeSelection": [],
		"state": "MN",
		"pricesAndPrizes": retVal,
		"homeBudgetResults": []
	};
}

function pricesAndPrizesInit(price, prizesArray) {
	return {
		"price": price,
		"prizes": prizesArray
	};
}

const pricesAndPrizesSlice = createSlice({
	name: 'scratch-tickets',
	initialState: await loadPricesAndPrizes2(),
	reducers: {
		load(state, action) {
			console.log(action.payload);
			const pricesAndPrizes = loadPricesAndPrizes2;
			state.state = pricesAndPrizes.state;
			state.pricesAndPrizes = pricesAndPrizes.pricesAndPrizes;
		},
		changeMinTixPrice(state, action) {
			console.log("changeMinTixPrice: "+action.payload);
			state.minTixPrice = action.payload;
		},
		changeMaxTixPrice(state, action) {
			state.maxTixPrice = action.payload;
		},
		changePrizeSelection(state, action) {
			state.prizeSelection = action.payload;
		},
		changeBudget(state, action) {
			state.budget = action.payload;
		},
		changeHomeBudgetResults(state, action) {
			state.homeBudgetResults = action.payload;
		},
	},
});

export const pricesAndPrizesActions = pricesAndPrizesSlice.actions;

export default pricesAndPrizesSlice.reducer;