import { Outlet, useLoaderData, useNavigation, useSearchParams, useSubmit } from 'react-router-dom';

import MainNavigation from '../components/MainNavigation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTokenDuration, getTokensFromCode } from '../util/auth';
import { authActions } from "../store/auth";

function RootLayout() {
	const dispatch = useDispatch();
	const token = useLoaderData();
	const submit = useSubmit();
	const [searchParams, setSearchParams] = useSearchParams();

	// const navigation = useNavigation();
	useEffect(() => {
		const code = searchParams.get("code");
		if (code) {
			searchParams.delete("code");
			const result = getTokensFromCode(code,dispatch);
//			dispatch(authActions.login(result));
		}

		if (!token) {
			return;
		}

		if (token === 'EXPIRED') {
			submit(null,
				{
					action: '/logout',
					method: 'post'
				}
			);
		}

		const tokenDuration = getTokenDuration();
		console.log(tokenDuration);

		setTimeout(() => {
			submit(null,
				{
					action: '/logout',
					method: 'post'
				}
			);
		}, tokenDuration);
	}, [token, submit, searchParams]);

	return (
		<>
			<MainNavigation />
			<main>
				{/* {navigation.state === 'loading' && <p>Loading...</p>} */}
				<Outlet />
			</main>
		</>
	);
}

export default RootLayout;
